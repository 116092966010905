import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";

import { getDatagridContent } from "utils/dataHelper";
import { fetchGroups } from "repos/group";

const GroupList = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  const fetchData = useCallback(async () => {
    fetchGroups().then((data) => {
      const editCallback = (params) => {
        navigate("/groups/" + params.id, { state: params.row });
      };
      const { rows, columns } = getDatagridContent(
        data,
        ["id", "key"],
        editCallback
      );
      setRows(rows);
      setColumns(columns);
    });
  }, [navigate]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div id="group-list">
      <br />
      <br />
      <DataGrid rows={rows} columns={columns} />
    </div>
  );
};

export default GroupList;
