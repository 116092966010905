import { IconButton } from "@mui/material";
import {
  Edit,
  Delete,
  Visibility,
  ToggleOff,
  ToggleOn,
} from "@mui/icons-material";

export const getDatagridContent = (
  data = [],
  excludedFields = [],
  editCallback,
  detailCallback,
  deleteCallback,
  selectToggleCallback
) => {
  if (!data.length) return { rows: [], columns: [] };

  const fields = Object.keys(data[0]).filter(
    (k) => !excludedFields.includes(k)
  );

  const columns = fields.map((f) => ({ field: f, headerName: f }));

  const customColumns = [];

  if (selectToggleCallback) {
    customColumns.push({
      field: "select",
      headerName: "",
      width: 30,
      renderCell: (params) => (
        <IconButton
          onClick={() => {
            selectToggleCallback(params);
          }}
        >
          <input type="checkbox" />
        </IconButton>
      ),
    });
  }

  if (deleteCallback) {
    customColumns.push({
      field: "delete",
      headerName: "",
      width: 30,
      renderCell: (params) => (
        <IconButton
          color="error"
          onClick={async () => {
            if (confirm("Emin misiniz?")) {
              await deleteCallback(params);
            }
          }}
        >
          <Delete />
        </IconButton>
      ),
    });
  }

  if (detailCallback) {
    customColumns.push({
      field: "details",
      headerName: "",
      width: 30,
      renderCell: (params) => (
        <IconButton
          color="primary"
          onClick={() => {
            detailCallback(params);
          }}
        >
          <Visibility />
        </IconButton>
      ),
    });
  }

  if (editCallback) {
    customColumns.push({
      field: "edit",
      headerName: "",
      width: 30,
      renderCell: (params) => (
        <IconButton
          color="primary"
          onClick={() => {
            editCallback(params);
          }}
        >
          <Edit />
        </IconButton>
      ),
    });
  }

  return {
    rows: data,
    columns: [...customColumns, ...columns],
  };
};
