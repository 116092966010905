import api from "integration/api";
import { handleError } from "utils/genericHelper";
import cities from "data/cities.json";
import jobs from "data/jobs.json";
import branches from "data/branches.json";
import titles from "data/titles.json";

export const fetchMembers = async () => {
  try {
    const response = await api.fetchMembersServer();
    if (response.success) {
      for (let i = 0; i < response.data.length; i++) {
        const cur = response.data[i];
        delete cur.role;
        delete cur.country;
        delete cur.fullName;
        delete cur.avatar;
        delete cur.settings;
        delete cur.pushTokenList;
        delete cur.phoneCode;
        delete cur.passwordHash;

        if (cur.city) {
          const city = cities.find((c) => c.key == cur.city);
          cur.city = "(" + cur.city + ") " + city.label;
          if (cur.county) {
            const county = city.counties.find((ct) => ct.key == cur.county);
            cur.county = "(" + cur.county + ") " + county.label;
          }
        }
        if (cur.job) {
          const jobBranches = branches["list" + cur.job];
          const job = jobs.find((j) => j.key == cur.job);
          cur.job = job.label;

          if (cur.branch) {
            const branch = jobBranches.find((b) => b.key == cur.branch);
            cur.branch = branch.label;
          }
        }
        if (cur.title) {
          const title = titles.find((t) => t.key == cur.title);
          cur.title = title?.label;
        }
      }

      return response.data;
    } else {
      handleError(response.error);
    }
  } catch (err) {
    handleError(err);
  }
};

export const updateMember = async (member) => {
  try {
    const response = await api.updateMemberServer(member);
    if (response.success) {
      return response.data || [];
    } else {
      handleError(response.error);
    }
  } catch (err) {
    handleError(err);
  }
};

export const updateMemberProfile = async (newProfile) => {
  try {
    const response = await api.updateMemberProfileServer(newProfile);
    if (response.success) {
      return response.data;
    } else {
      handleError(response.error);
    }
  } catch (err) {
    handleError(err);
  }
};
