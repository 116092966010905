export const REGEXES = {
  Phone: /^\d{10,10}$/,
  Password: /^\d{6,10}$/,
  Name: /^[a-zA-Z ]{2,30}$/,
  True: /^true$/, //onayi zorunlu olan checkboxlar icin
  OTP: /^\d{4,4}$/,
  EntryShort: /^[\w\W]{2,100}$/,
  EntryMid: /^[\w\W]{2,250}$/,
  EntryBig: /^[\w\W]{2,500}$/,
  Employer: /^[\w\W]{3,100}$/,
  Email: /^$|^.*@.*\..*$/,
  Search: /^[\w\W]{3,20}$/,
};

export const CONTENT_TYPE = Object.freeze({
  News: "news",
  Education: "education",
  Announcement: "announcement",
  Congress: "congress",
  Document: "document",
});

export const OPS = Object.freeze({
  Fill: "Fill",
  Add: "Add",
  Update: "Update",
  Delete: "Delete",
});
