import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";

import { getDatagridContent } from "utils/dataHelper";
import { fetchCampaigns } from "repos/campaign";

const CampaignList = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  const fetchData = useCallback(async () => {
    fetchCampaigns().then((data) => {
      const editCallback = (params) => {
        navigate("/campaigns/" + params.id, { state: params.row });
      };

      const { rows, columns } = getDatagridContent(
        data,
        ["id", "key"],
        editCallback
      );
      setRows(rows);
      setColumns(columns);
    });
  }, [navigate]);

  useEffect(() => {
    fetchData();
  }, []);

  const handleAdd = async (event) => {
    navigate("/campaigns/0");
  };

  return (
    <div id="campaign-list">
      <Button variant="contained" onClick={handleAdd}>
        +Add Campaign
      </Button>

      <br />
      <br />
      <DataGrid rows={rows} columns={columns} />
    </div>
  );
};

export default CampaignList;
