import api from "integration/api";
import { handleError } from "utils/genericHelper";

export const fetchCampaigns = async () => {
  try {
    const response = await api.fetchCampaignsServer();
    if (response.success) {
      return response.data;
    } else {
      handleError(response.error);
    }
  } catch (err) {
    handleError(err);
  }
};

export const addCampaign = async (campaign) => {
  try {
    const response = await api.addCampaignServer(campaign);
    if (response.success) {
      return response.data;
    } else {
      handleError(response.error);
    }
  } catch (err) {
    handleError(err);
  }
};

export const updateCampaign = async (campaign) => {
  try {
    const response = await api.updateCampaignServer(campaign);
    if (response.success) {
      return response.data;
    } else {
      handleError(response.error);
    }
  } catch (err) {
    handleError(err);
  }
};

export const deleteCampaign = async (id) => {
  try {
    const response = await api.deleteCampaignServer(id);
    if (response.success) {
      return response.data;
    } else {
      handleError(response.error);
    }
  } catch (err) {
    handleError(err);
  }
};
