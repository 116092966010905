import { useCallback, useEffect, useState } from "react";
import {
  fetchNonPushedWhitecodes,
  pushWhitecode,
  deleteWhitecode,
} from "repos/whitecode";
import { formatDDDDHHMM } from "utils/appHelper";
import Loading from "views/_shared/Loading";
import WCDetailModal from "./WCDetailModal";

const WhitecodeList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedWC, setSelectedWC] = useState(null);

  const fetchData = useCallback(async () => {
    setLoading(true);
    fetchNonPushedWhitecodes().then((_data) => {
      setLoading(false);
      setData(_data);
    });
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const showDetail = (e) => {
    const id = e.target.dataset.id;
    setSelectedWC(data.find((el) => el.id === id));
  };

  const closeDetail = (e) => {
    setSelectedWC(null);
  };

  const approveCallback = async (id) => {
    setLoading(true);
    if (await pushWhitecode(id)) {
      alert("Onaylandı");
      await fetchData();
      closeDetail();
    } else {
      alert("Hata oluştu!");
    }
    setLoading(false);
  };

  const deleteCallback = async (id) => {
    setLoading(true);
    if (await deleteWhitecode(id)) {
      alert("Silindi");
      closeDetail();
    } else {
      alert("Hata oluştu!");
    }
    setLoading(false);
  };

  return (
    <div id="whitecode-list-mobile">
      {!loading && (
        <>
          <button onClick={fetchData}>↻ YENILE</button>
          <br />
          <br />
        </>
      )}

      {loading ? (
        <Loading />
      ) : (
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Üye adı</th>
              <th>Tarih</th>
            </tr>
          </thead>
          <tbody>
            {data.map((g, i) => {
              return (
                <tr key={i}>
                  <td>
                    <button
                      data-id={g.id}
                      onClick={showDetail}
                      style={{ padding: 10 }}
                    >
                      ⓘ
                    </button>
                  </td>
                  <td>{g.fullName || "-"} </td>
                  <td>{formatDDDDHHMM(g.createDate)}</td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={2}>{data.length === 0 ? "No records found" : ""}</td>
            </tr>
          </tfoot>
        </table>
      )}
      {selectedWC && (
        <WCDetailModal
          item={selectedWC}
          approveCallback={approveCallback}
          deleteCallback={deleteCallback}
          closeCallback={closeDetail}
          loading={loading}
        />
      )}
    </div>
  );
};

export default WhitecodeList;
