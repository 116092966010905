import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import * as memberRepo from "repos/member";

const MemberSave = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [form, setForm] = useState({
    id: "",
    phone: "",
    firstName: "",
    lastName: "",
    employer: "",
    active: false,
  });

  useEffect(() => {
    const item = location?.state || {
      id: "",
      phone: "",
      firstName: "",
      lastName: "",
      employer: "",
      active: false,
    };

    setForm({
      id: item.id || "",
      phone: item.phone || "",
      firstName: item.firstName || "",
      lastName: item.lastName || "",
      employer: item.employer || "",
      active: item.active ?? false,
    });
  }, [location]);

  const handleTextChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSave = async (e) => {
    if (!form.firstName || !form.lastName || !form.employer) {
      alert("zorunlu alanları doldurunuz");
      return;
    }

    const item = {
      id: form.id,
      phone: form.phone,
      firstName: form.firstName,
      lastName: form.lastName,
      employer: form.employer,
      active: form.active,
    };

    const saved = await memberRepo.updateMember(item);
    if (saved) {
      alert("saved");
      navigate("/members");
    }
  };

  const handleSwitch = (e) => {
    setForm({ ...form, active: e.target.checked });
  };

  return (
    <div id="content-save">
      <legend>ID: {form.id}</legend>
      <legend>Phone: +90 {form.phone}</legend>

      <legend>
        FirstName:
        <input
          type="text"
          id="firstName"
          name="firstName"
          value={form.firstName}
          maxLength={30}
          onChange={handleTextChange}
        />
        <label className="counter">
          Remaining: {30 - form.firstName?.length}
        </label>
      </legend>

      <legend>
        LastName:
        <input
          type="text"
          id="lastName"
          name="lastName"
          value={form.lastName}
          maxLength={30}
          onChange={handleTextChange}
        />
        <label className="counter">
          Remaining: {30 - form.lastName?.length}
        </label>
      </legend>

      <legend>
        Employer:
        <input
          type="text"
          id="employer"
          name="employer"
          value={form.employer}
          maxLength={100}
          onChange={handleTextChange}
        />
        <label className="counter">
          Remaining: {100 - form.employer?.length}
        </label>
      </legend>

      <legend>
        Active: <Switch checked={form.active} onChange={handleSwitch} />
      </legend>

      <div>
        <Button variant="contained" onClick={handleSave} className="large">
          SAVE
        </Button>
      </div>
    </div>
  );
};

export default MemberSave;
