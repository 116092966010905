import axios from "axios";
import { store } from "_redux/store";

const { REACT_APP_ENDPOINT, REACT_APP_API_TIMEOUT } = process.env;

axios.defaults.timeout = +REACT_APP_API_TIMEOUT;
const httpClient = axios.create();
httpClient.defaults.baseURL = REACT_APP_ENDPOINT;
httpClient.defaults.timeout = +REACT_APP_API_TIMEOUT;
httpClient.defaults.headers["Cache-Control"] = "no-cache";
httpClient.defaults.headers.Pragma = "no-cache";
httpClient.defaults.headers.Expires = 0;

let controller = new AbortController();

const setToken = async () => {
  const token = store.getState().app.jwt;
  if (token) {
    httpClient.defaults.headers["Authorization"] = `Bearer ${token}`;
  }
};

export const getRequest = async (url) => {
  await setToken();
  return await httpClient(url, {
    method: "get",
    headers: { "original-route": url.slice(1) },
  });
};

export const postRequest = async (url, data) => {
  await setToken();
  return await httpClient(url, {
    method: "post",
    data,
    headers: { "original-route": url.slice(1) },
  });
};

export const patchRequest = async (url, data) => {
  await setToken();
  return await httpClient(url, {
    method: "patch",
    data,
    headers: { "original-route": url.slice(1) },
  });
};

export const deleteRequest = async (url, data) => {
  await setToken();
  return await httpClient(url, {
    method: "delete",
    data,
    headers: { "original-route": url.slice(1) },
  });
};

export const uploadRequest = async (url, file) => {
  await setToken();

  const config = {
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
      "original-route": url.slice(1),
    },
    signal: controller.signal,
  };

  return await httpClient.post(url, file, config);
};

export const buildResponse = (error, apiResponse) => {
  if (error) {
    return {
      success: false,
      error,
      data: null,
    };
  }

  if (apiResponse?.data?.error) {
    return {
      success: false,
      error: apiResponse.data.error,
      data: apiResponse.data.payload,
    };
  } else {
    return {
      success: true,
      error: null,
      data: apiResponse.data.payload,
    };
  }
};

export const cancelRequest = () => {
  controller.abort();
  controller = new AbortController();
};
