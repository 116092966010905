import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setJWTAction } from "_redux/app/actions";
import { login } from "repos/app";
import "./login.css";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [form, setForm] = useState({
    phoneCode: "+90",
    phone: process.env.REACT_APP_TEST_PHONE || "",
    password: process.env.REACT_APP_TEST_PASSWORD || "",
  });

  const handleLogin = async () => {
    const response = await login({
      username: form.phoneCode + form.phone,
      password: form.password,
    });
    if (response) {
      const { jwt } = response;
      dispatch(setJWTAction(jwt));
      setTimeout(() => {
        navigate("groups");
      }, 1000);
    } else {
      alert("invalid creds");
    }
  };

  const handleTextChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  return (
    <div id="login">
      <div>
        <h3>LOGIN</h3>
        <div>
          <span>
            +90
            <input
              type="text"
              id="phone"
              name="phone"
              placeholder="phone number"
              value={form.phone}
              onChange={handleTextChange}
            />
          </span>
        </div>

        <input
          type="password"
          id="password"
          name="password"
          placeholder="password"
          value={form.password}
          onChange={handleTextChange}
        />
        <input type="button" value="login" onClick={handleLogin} />
      </div>
    </div>
  );
};

export default Login;
