import { json, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import * as campaignRepo from "repos/campaign";
import { fetchTargetLists } from "repos/target";

const CampaignSave = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [allLists, setAllLists] = useState([]);
  const [lists, setLists] = useState([]);
  const [selectedListId, setSelectedListId] = useState("-1");

  const [form, setForm] = useState({
    title: "",
    targetLists: "",
    category: "food",
    closable: false,
    showcase: false,
    targetUrl: "",
    sizeValue: "",
    sizeUnit: "",
    imageInfoUrl: "",
    imageInfoThumbnailUrl: "",
    textInfoBrand: "",
    textInfoBrandLong: "",
    textInfoSubtitle1: "",
    textInfoSubtitle2: "",
    textInfoSubtitle3: "",
    textInfoSubtitle4: "",
    textInfoSubtitle5: "",
    isActive: false,
  });

  useEffect(() => {
    fetchTargetLists().then((data) => {
      setAllLists(data);
      setLists(
        data.filter((el) =>
          (location?.state?.targetLists || "").split(",").includes(el.id)
        )
      );
    });
  }, []);

  useEffect(() => {
    const item = location?.state || {
      id: "",
      title: "",
      targetLists: "",
      detail:
        '{"category":"food","closable":false,"showcase":false,"targetUrl":"","size":{"value":0,"unit":"%"},"imageInfo":null,"textInfo":{"brand":"","brandLong":"","subtitle1":"","subtitle2":"","subtitle3":"","subtitle4":"","subtitle5":""}}',
      isActive: false,
    };

    const detail = JSON.parse(item.detail);
    setForm({
      id: item.id || "",
      title: item.title || "",
      targetLists: item.targetLists || "",
      category: detail.category,
      closable: detail.closable,
      showcase: detail.showcase,
      targetUrl: detail.targetUrl,
      sizeValue: detail.size.value,
      sizeUnit: detail.size.unit,
      imageInfoUrl: detail.imageInfo?.imageUrl,
      imageInfoThumbnailUrl: detail.imageInfo?.thumbnailUrl,
      textInfoBrand: detail.textInfo?.brand,
      textInfoBrandLong: detail.textInfo?.brandLong,
      textInfoSubtitle1: detail.textInfo?.subtitle1,
      textInfoSubtitle2: detail.textInfo?.subtitle2,
      textInfoSubtitle3: detail.textInfo?.subtitle3,
      textInfoSubtitle4: detail.textInfo?.subtitle4,
      textInfoSubtitle5: detail.textInfo?.subtitle5,
      isActive: item.isActive ?? false,
    });
  }, [location]);

  const handleTextChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSave = async (e) => {
    if (!form.title || !(form.imageInfoUrl || form.textInfoBrand)) {
      alert("Zorunlu alanları doldurunuz");
      return;
    }

    const item = {
      id: form.id,
      title: form.title,
      targetLists: lists.map((el) => el.id).join(","),
      detail: JSON.stringify({
        category: form.category,
        closable: form.closable,
        showcase: form.showcase,
        targetUrl: form.targetUrl,
        size: {
          value: form.sizeValue,
          unit: form.sizeUnit,
        },
        imageInfo: form.imageInfoUrl
          ? {
              imageUrl: form.imageInfoUrl,
              thumbnailUrl: form.imageInfoThumbnailUrl,
            }
          : null,
        textInfo: form.textInfoBrand
          ? {
              brand: form.textInfoBrand,
              brandLong: "",
              subtitle1: form.textInfoSubtitle1,
              subtitle2: form.textInfoSubtitle2,
              subtitle3: form.textInfoSubtitle3,
              subtitle4: "",
              subtitle5: "",
            }
          : null,
      }),
      isActive: form.isActive,
    };

    const saved = form.id
      ? await campaignRepo.updateCampaign(item)
      : await campaignRepo.addCampaign(item);
    if (saved) {
      alert("saved");
      navigate("/campaigns");
    }
  };

  const handleActiveSwitch = (e) => {
    setForm({ ...form, isActive: e.target.checked });
  };

  const handleShowcaseSwitch = (e) => {
    setForm({ ...form, showcase: e.target.checked });
  };

  const handleClosableSwitch = (e) => {
    setForm({ ...form, closable: e.target.checked });
  };

  const handleAddList = (e) => {
    console.log({ lists });
    if (selectedListId !== "-1") {
      if (!lists.find((el) => el.id === selectedListId)) {
        const item = allLists.find((el) => el.id === selectedListId);
        setLists([...lists, item]);
      }
    }
  };

  const handleDeleteList = (e) => {
    const id = e.target.dataset.id;
    setLists(lists.filter((el) => el.id !== id));
  };

  const handleListChange = (e) => {
    setSelectedListId(e.target.value);
  };

  const handleUnitChange = (e) => {
    setForm({ ...form, sizeUnit: e.target.value });
  };

  const handleCategoryChange = (e) => {
    setForm({ ...form, category: e.target.value });
  };

  const handleDelete = async (e) => {
    if (confirm("Silmek istediginize emin misiniz?")) {
      await campaignRepo.deleteCampaign(form.id);
      alert("silindi");
      navigate("/campaigns");
    }
  };

  return (
    <div id="campaign-save">
      <legend>
        Title:
        <textarea
          id="title"
          name="title"
          value={form.title}
          maxLength={500}
          onChange={handleTextChange}
        ></textarea>
        <label className="counter">Remaining: {500 - form.title?.length}</label>
      </legend>

      <legend>
        TARGET LISTS:
        <table border={1}>
          <tbody>
            <tr>
              <td>
                <select
                  style={{ width: "100%" }}
                  value={selectedListId}
                  onChange={handleListChange}
                >
                  <option value={"-1"}>Seçiniz</option>
                  {allLists.map((el) => (
                    <option key={el.id} value={el.id}>
                      {el.title}
                    </option>
                  ))}
                </select>
              </td>
              <td style={{ width: 80 }}>
                <button onClick={handleAddList}>Add List</button>
              </td>
            </tr>

            {lists.map((el) => {
              return (
                <tr key={el.id}>
                  <td>{el.title}</td>
                  <td>
                    <button data-id={el.id} onClick={handleDeleteList}>
                      sil
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </legend>

      <legend>
        Target Url:
        <textarea
          id="targetUrl"
          name="targetUrl"
          value={form.targetUrl}
          maxLength={1000}
          onChange={handleTextChange}
        ></textarea>
        <label className="counter">
          Remaining: {1000 - form.targetUrl?.length}
        </label>
      </legend>

      <legend>
        Is closable:{" "}
        <Switch checked={form.closable} onChange={handleClosableSwitch} />
      </legend>

      <legend>
        Is showcase:{" "}
        <Switch checked={form.showcase} onChange={handleShowcaseSwitch} />
      </legend>

      <legend>
        Size unit:{" "}
        <select value={form.sizeUnit} onChange={handleUnitChange}>
          <option value="value">value</option>
          <option value="%">%</option>
        </select>
      </legend>

      <legend>
        Size value:{" "}
        <input
          id="sizeValue"
          name="sizeValue"
          type="number"
          value={form.sizeValue}
          onChange={handleTextChange}
        />
      </legend>

      <legend>
        Image url:{" "}
        <input
          id="imageInfoUrl"
          name="imageInfoUrl"
          type="text"
          value={form.imageInfoUrl}
          onChange={handleTextChange}
        />
      </legend>

      <legend>
        Thumbnail url:{" "}
        <input
          id="imageInfoThumbnailUrl"
          name="imageInfoThumbnailUrl"
          type="text"
          value={form.imageInfoThumbnailUrl}
          onChange={handleTextChange}
        />
      </legend>

      <legend>
        Brand:
        <input
          id="textInfoBrand"
          name="textInfoBrand"
          type="text"
          value={form.textInfoBrand}
          onChange={handleTextChange}
        />
      </legend>

      <legend>
        Category:
        <select value={form.category} onChange={handleCategoryChange}>
          <option value="cafe">cafe</option>
          <option value="cleaning">cleaning</option>
          <option value="education">education</option>
          <option value="food">food</option>
          <option value="furniture">furniture</option>
          <option value="gas">gas</option>
          <option value="gift">gift</option>
          <option value="hotel">hotel</option>
          <option value="jewel">jewel</option>
          <option value="music">music</option>
          <option value="optic">optic</option>
          <option value="sport">sport</option>
          <option value="stationery">stationery</option>
          <option value="watch">watch</option>
        </select>
      </legend>

      <legend>
        Subtitle1:
        <input
          id="textInfoSubtitle1"
          name="textInfoSubtitle1"
          type="text"
          value={form.textInfoSubtitle1}
          onChange={handleTextChange}
        />
      </legend>

      <legend>
        Subtitle2:
        <input
          id="textInfoSubtitle2"
          name="textInfoSubtitle2"
          type="text"
          value={form.textInfoSubtitle2}
          onChange={handleTextChange}
        />
      </legend>

      <legend>
        Subtitle3:
        <input
          id="textInfoSubtitle3"
          name="textInfoSubtitle3"
          type="text"
          value={form.textInfoSubtitle3}
          onChange={handleTextChange}
        />
      </legend>

      <legend>
        Active: <Switch checked={form.isActive} onChange={handleActiveSwitch} />
      </legend>

      <div>
        <Button variant="contained" onClick={handleSave} className="large">
          SAVE
        </Button>
        &nbsp;&nbsp;&nbsp;
        {form.id && (
          <Button
            variant="contained"
            color="error"
            onClick={handleDelete}
            className="large"
          >
            DELETE
          </Button>
        )}
      </div>
    </div>
  );
};

export default CampaignSave;
