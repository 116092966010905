import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { updateTargetList, createTargetList } from "repos/target";

const TargetSave = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [form, setForm] = useState({
    active: false,
  });

  useEffect(() => {
    const item = location?.state || {
      id: "",
      title: "",
      matcher: "",
    };

    setForm({
      id: item.id || "",
      title: item.title || "",
      matcher: item.matcher || "",
    });
  }, [location]);

  const handleTextChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSave = async (e) => {
    if (!form.title) {
      alert("zorunlu alanları doldurunuz");
      return;
    }

    const item = {
      id: form.id,
      title: form.title,
      matcher: form.matcher,
    };

    if (form.id) {
    }
    const saved = form.id
      ? await updateTargetList(item)
      : await createTargetList(item);
    if (saved) {
      alert("saved");
      navigate("/targets");
    }
  };

  return (
    <div id="content-save">
      <legend>ID: {form.id}</legend>

      <legend>
        title:
        <input
          type="title"
          id="title"
          name="title"
          value={form.title}
          maxLength={250}
          onChange={handleTextChange}
        />
        <label className="counter">Remaining: {250 - form.title?.length}</label>
      </legend>

      <legend>
        matcher: (select * from vwMember where 1=1 and ...)
        <input
          type="matcher"
          id="matcher"
          name="matcher"
          value={form.matcher}
          maxLength={1000}
          onChange={handleTextChange}
        />
        <label className="counter">
          Remaining: {1000 - form.matcher?.length}
        </label>
      </legend>

      <div>
        <Button variant="contained" onClick={handleSave} className="large">
          SAVE
        </Button>
      </div>
    </div>
  );
};

export default TargetSave;
