import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { initStore } from "./_redux/store";
import routes from "routes";

function App() {
  const [initialStore, setInitialStore] = useState(null);

  useEffect(() => {
    initStore().then((s) => setInitialStore(s));
  }, []);

  return initialStore ? (
    <Provider store={initialStore}>
      <RouterProvider router={routes} />
    </Provider>
  ) : null;
}

export default App;
