import { useCallback, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

import { getDatagridContent } from "utils/dataHelper";
import { fetchTargetListMembers } from "repos/target";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Modal } from "@mui/material";
import MemberSelectionPopup from "views/_shared/MemberSelectionPopup";
import * as targetRepo from "repos/target";

const MemberList = () => {
  const location = useLocation();
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [list, setList] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);
  const [existingMemberIds, setExistingMemberIds] = useState([]);

  useEffect(() => {
    const item = location?.state || {
      id: "",
      title: "",
      matcher: "",
    };

    setList({
      id: item.id || "",
      title: item.title || "",
      matcher: item.matcher || "",
    });

    if (item.id) {
      fetchData(item.id);
    }
  }, [location]);

  const fetchData = useCallback(async (listId) => {
    fetchTargetListMembers(listId).then((listMembers) => {
      setExistingMemberIds(listMembers.map((m) => m.id.toLowerCase()));

      const deleteCallback = async (params) => {
        await targetRepo.deleteTargetListMember(listId, params.row.id);
        alert("üye listeden silindi!");
        //refresh data
        updateGridData(
          listMembers.filter((d) => d.id !== params.row.id),
          deleteCallback
        );
      };
      updateGridData(listMembers, deleteCallback);
    });
  }, []);

  const updateGridData = (data, deleteCallback) => {
    const { rows, columns } = getDatagridContent(
      data,
      ["id", "key"],
      null,
      null,
      deleteCallback
    );
    setRows(rows);
    setColumns(columns);
  };

  const handleRefill = async () => {
    if (!list.matcher) {
      alert("Matcher boş olamaz");
      return;
    }
    if (confirm("Tüm liste yeniden doldurulacak, emin misiniz?")) {
      await targetRepo.refillTargetList(list.id);
      await fetchData(list.id);
      alert("list yeniden dolduruldu!");
    }
  };

  const handleEmpty = async () => {
    if (confirm("Tüm liste boşaltılacak, emin misiniz?")) {
      await targetRepo.emptyTargetList(list.id);
      await fetchData(list.id);
      alert("list boşaltıldı!");
    }
  };

  const handleOpenPopup = async () => {
    setPopupOpen(true);
  };

  const handleClosePopup = async (selections) => {
    await targetRepo.addTargetListMembers(
      list.id,
      selections.map((s) => s.id)
    );
    if (selections.length) {
      alert("listeye seçili üyeler eklendi!");
    }

    await fetchData(list.id);
    setPopupOpen(false);
  };

  return (
    <div id="member-list">
      <Button variant="contained" onClick={handleOpenPopup}>
        +Add Member to List
      </Button>
      &nbsp;&nbsp;
      <Button color="secondary" variant="contained" onClick={handleRefill}>
        REFILL
      </Button>
      &nbsp;&nbsp;
      <Button color="error" variant="contained" onClick={handleEmpty}>
        EMPTY LIST
      </Button>
      <br />
      <br />
      Title: {list?.title} <br />
      Matcher:
      {list?.matcher}
      <br />
      <br />
      Total: {rows.length}
      <br />
      <br />
      <DataGrid rows={rows} columns={columns} />
      {popupOpen && (
        <Modal
          open={popupOpen}
          style={{ background: "white", padding: "1em" }}
          hideBackdrop
        >
          <MemberSelectionPopup
            onClose={handleClosePopup}
            activeOnly
            excludedMemberIds={existingMemberIds}
          />
        </Modal>
      )}
    </div>
  );
};

export default MemberList;
