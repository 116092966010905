import {
  SET_JWT_TYPE,
  SET_LOADING_TYPE,
  UPDATE_CONFIG_FROM_SERVER_TYPE,
} from './actionTypes';

export const setJWTAction = jwt => ({
  type: SET_JWT_TYPE,
  payload: {jwt},
});

export const setLoadingAction = loading => ({
  type: SET_LOADING_TYPE,
  payload: {loading},
});

export const updateConfigFromServerAction = serverConfig => ({
  type: UPDATE_CONFIG_FROM_SERVER_TYPE,
  payload: {serverConfig},
});
