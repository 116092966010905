import { useRef, useState } from "react";
import Switch from "@mui/material/Switch";
import { findFileExtension } from "utils/genericHelper";
import { buildImageUrl } from "utils/appHelper";
import { upload } from "repos/app";

const ContentMedia = ({
  videoUrl,
  images,
  uploadCallback,
  deleteImageCallback,
}) => {
  const file1Ref = useRef();
  const file2Ref = useRef();
  const [loading, setLoading] = useState(false);
  const [isVideoContent, setIsVideoContent] = useState(false);
  const [isYoutube, setIsYoutube] = useState(true);

  const handleTextChange = (e) => {
    uploadCallback("video", e.target.value);
  };

  const handleFilePick = async (e) => {
    if (!e.target.files.length) return false;
    const file = e.target.files[0];

    if (e.target.name === "file1") {
      handleVideoPick(file);
    } else {
      handleImagePick(file);
    }
  };

  const handleVideoPick = async (file) => {
    const ext = findFileExtension(file.name);
    if (file.name.endsWith(".mp4")) {
      if (file.size > 20 * 1024 * 1024) {
        alert("file size must be < 20 mb");
        file1Ref.current.value = "";
        return;
      } else {
        setLoading(true);
        const url = await upload("content/videos", ext, file);
        setLoading(false);
        uploadCallback("video", url);
      }
    } else {
      alert("only mp4 supported");
      file1Ref.current.value = "";
    }
  };

  const handleImagePick = async (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      const image = new Image();
      image.src = e.target.result;
      image.onload = async () => {
        const { height, width } = image;

        const ext = findFileExtension(file.name);
        if (
          file.name.endsWith(".gif") ||
          file.name.endsWith(".png") ||
          file.name.endsWith(".jpg") ||
          file.name.endsWith(".jpeg")
        ) {
          if (file.size > 5 * 1024 * 1024) {
            alert("file size must be < 5 mb");
            file2Ref.current.value = "";
            return;
          } else {
            setLoading(true);
            const url = await upload("content/images", ext, file);
            setLoading(false);
            uploadCallback("image", url, width, height);
          }
        } else {
          alert("gif / png / jpg / jpeg extensions supported");
          file2Ref.current.value = "";
        }
        return true;
      };
    };
  };

  const handleDeleteImage = (e) => {
    deleteImageCallback(e.target.dataset.uri);
  };

  const handleSwitch = (e) => {
    if (e.target.name === "videopick") {
      setIsVideoContent(e.target.checked);
    } else {
      setIsYoutube(e.target.checked);
    }
  };

  return (
    <>
      <legend>
        Image Gallery / Video
        <Switch
          name="videopick"
          checked={isVideoContent}
          onChange={handleSwitch}
        />
      </legend>
      {isVideoContent ? (
        <legend className="videobox">
          <h3>VIDEO</h3>
          <Switch checked={isYoutube} onChange={handleSwitch} />
          {isYoutube ? (
            <>
              Youtube link:
              <textarea
                id="youtube"
                name="youtube"
                value={videoUrl}
                maxLength={500}
                onChange={handleTextChange}
              ></textarea>
            </>
          ) : (
            <>
              Current video: {videoUrl}
              <input
                type="file"
                id="file1"
                name="file1"
                onChange={handleFilePick}
                accept=".mp4"
                ref={file1Ref}
                style={{ visibility: loading ? "hidden" : "visible" }}
              />
              {loading && "uploading..."}
            </>
          )}
        </legend>
      ) : (
        <legend className="imagebox">
          <h3>IMAGES</h3>
          <input
            type="file"
            id="file2"
            name="file2"
            onChange={handleFilePick}
            accept="image/*"
            ref={file2Ref}
            style={{ visibility: loading ? "hidden" : "visible" }}
          />
          {loading && "uploading..."}
          <div className="gallery">
            {images.map((image, i) => {
              return (
                <div key={i}>
                  <img
                    src={buildImageUrl(image.uri)}
                    className="gallery-item"
                    alt="i"
                  />
                  <br />
                  <button data-uri={image.uri} onClick={handleDeleteImage}>
                    delete image
                  </button>
                </div>
              );
            })}
          </div>
        </legend>
      )}
    </>
  );
};

export default ContentMedia;
