import { useCallback, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

import { getDatagridContent } from "utils/dataHelper";
import { fetchMembers } from "repos/member";
import "./shared.css";
import { Button } from "@mui/material";

const MemberSelectionPopup = ({
  onClose,
  excludedMemberIds = [],
  activeOnly = false,
}) => {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selections, setSelections] = useState([]);

  const fetchData = async () => {
    fetchMembers().then((data1) => {
      let data = activeOnly
        ? data1.filter(
            (d) => !excludedMemberIds.includes(d.id.toLowerCase()) && d.active
          )
        : data1.filter((d) => !excludedMemberIds.includes(d.id.toLowerCase()));

      for (let i = 0; i < data.length; i++) {
        const cur = data[i];
        delete cur.memberId;
        delete cur.email;
        delete cur.facebook;
        delete cur.twitter;
        delete cur.linkedin;
        delete cur.instagram;
        delete cur.bio;
      }

      const { rows, columns } = getDatagridContent(
        data,
        ["key"],
        null,
        null,
        null,
        selectToggleCallback
      );
      setRows(rows);
      setColumns(columns);
    });
  };

  const selectToggleCallback = (params) => {
    const member = params.row;
    setSelections((v) => {
      return v.find((s) => s.id.toLowerCase() === member.id.toLowerCase())
        ? v.filter((s) => s.id.toLowerCase() !== member.id.toLowerCase())
        : [...v, member];
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div id="member-list">
      <Button variant="contained" onClick={() => onClose(selections)}>
        +Add Selected Members to List
      </Button>
      &nbsp;&nbsp;&nbsp;
      <Button variant="contained" onClick={() => onClose([])} color="error">
        Cancel
      </Button>
      <br />
      <br />
      Total: {rows.length}
      <br />
      Selections:{" "}
      {selections.map((s) => s.firstName + " " + s.lastName).join(" , ")}
      <br />
      <DataGrid rows={rows} columns={columns} />
    </div>
  );
};

export default MemberSelectionPopup;
