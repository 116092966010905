import { useCallback, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

import { getDatagridContent } from "utils/dataHelper";
import { fetchMembers } from "repos/member";
import { useNavigate } from "react-router-dom";

const MemberList = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  const fetchData = useCallback(async () => {
    fetchMembers().then((data) => {
      const editCallback = (params) => {
        navigate("/members/" + params.id, { state: params.row });
      };
      const { rows, columns } = getDatagridContent(
        data,
        ["id", "key"],
        editCallback
      );
      setRows(rows);
      setColumns(columns);
    });
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div id="member-list">
      Total: {rows.length}
      <br />
      <br />
      <DataGrid rows={rows} columns={columns} />
    </div>
  );
};

export default MemberList;
