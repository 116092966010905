import api from "integration/api";
import { handleError } from "utils/genericHelper";

export const fetchGroups = async () => {
  try {
    const response = await api.fetchGroupsServer();
    if (response.success) {
      return response.data || [];
    } else {
      handleError(response.error);
    }
  } catch (err) {
    handleError(err);
  }
};

export const updateGroup = async (group) => {
  try {
    const response = await api.updateGroupServer(group);
    if (response.success) {
      return response.data;
    } else {
      handleError(response.error);
    }
  } catch (err) {
    handleError(err);
  }
};

export const approveGroup = async (id) => {
  try {
    const response = await api.approveGroupServer(id);
    if (response.success) {
      return true;
    } else {
      handleError(response.error);
    }
  } catch (err) {
    handleError(err);
  }
  return false;
};

export const fetchGroupMembers = async (groupId = null) => {
  try {
    const response = await api.fetchGroupMembersServer(groupId);
    if (response.success) {
      return response.data || [];
    } else {
      handleError(response.error);
    }
  } catch (err) {
    handleError(err);
  }
};

export const fetchGroupFiles = async (groupId = null) => {
  try {
    const response = await api.fetchGroupFilesServer(groupId);
    if (response.success) {
      return response.data || [];
    } else {
      handleError(response.error);
    }
  } catch (err) {
    handleError(err);
  }
};
