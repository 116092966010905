import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";

import { getDatagridContent } from "utils/dataHelper";
import { CONTENT_TYPE } from "utils/enums";
import { fetchContents } from "repos/content";

const ContentList = () => {
  const navigate = useNavigate();
  const [category, setCategory] = useState(CONTENT_TYPE.News);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  const fetchData = useCallback(
    async (_category) => {
      fetchContents(_category).then((data) => {
        const editCallback = (params) => {
          navigate("/contents/" + params.id, { state: params.row });
        };
        const { rows, columns } = getDatagridContent(
          data,
          ["id", "key"],
          editCallback
        );
        setRows(rows);
        setColumns(columns);
      });
    },
    [navigate]
  );

  useEffect(() => {
    fetchData(category);
  }, [fetchData, category]);

  const handleChange = async (event) => {
    setCategory(event.target.value);
    fetchData(event.target.value);
  };

  const handleAdd = async (event) => {
    navigate("/contents/0");
  };

  return (
    <div id="content-list">
      <Button variant="contained" onClick={handleAdd}>
        +Add Content
      </Button>

      <br />
      <br />
      <Select
        labelId="category"
        id="category"
        value={category}
        label="Kategori"
        onChange={handleChange}
      >
        <MenuItem value={CONTENT_TYPE.News}>News</MenuItem>
        <MenuItem value={CONTENT_TYPE.Announcement}>Announcement</MenuItem>
        <MenuItem value={CONTENT_TYPE.Congress}>Congress</MenuItem>
        <MenuItem value={CONTENT_TYPE.Document}>Document</MenuItem>
        <MenuItem value={CONTENT_TYPE.Education}>Education</MenuItem>
      </Select>
      <br />
      <br />
      <DataGrid rows={rows} columns={columns} />
    </div>
  );
};

export default ContentList;
