import api from "integration/api";
import { handleError } from "utils/genericHelper";

export const fetchWhitecodes = async () => {
  try {
    const response = await api.fetchWhitecodesServer();
    if (response.success) {
      return response.data;
    } else {
      handleError(response.error);
    }
  } catch (err) {
    handleError(err);
  }
};

export const pushWhitecode = async (id) => {
  try {
    const response = await api.pushWhitecodeServer(id);
    if (response.success) {
      return true;
    } else {
      handleError(response.error);
    }
  } catch (err) {
    handleError(err);
  }
  return false;
};

export const deleteWhitecode = async (id) => {
  try {
    const response = await api.deleteWhitecodeServer(id);
    if (response.success) {
      return true;
    } else {
      handleError(response.error);
    }
  } catch (err) {
    handleError(err);
  }
  return false;
};

export const fetchNonPushedWhitecodes = async () => {
  try {
    const response = await api.fetchWhitecodesServer({ isPushed: false });
    if (response.success) {
      return response.data;
    } else {
      handleError(response.error);
    }
  } catch (err) {
    handleError(err);
  }
};
