import { useCallback, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

import { getDatagridContent } from "utils/dataHelper";
import { fetchTargetLists } from "repos/target";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { deleteTargetList } from "repos/target";

const TargetList = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  const fetchData = useCallback(async () => {
    fetchTargetLists().then((data) => {
      const editCallback = (params) => {
        navigate("/targets/" + params.id, { state: params.row });
      };
      const detailCallback = (params) => {
        navigate("/target-members/" + params.id, { state: params.row });
      };
      const deleteCallback = async (params) => {
        await deleteTargetList(params.id);
        alert("Silindi");
        fetchData();
      };
      const { rows, columns } = getDatagridContent(
        data,
        ["id", "key"],
        editCallback,
        detailCallback,
        deleteCallback
      );
      setRows(rows);
      setColumns(columns);
    });
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const handleAdd = async (event) => {
    navigate("/targets/0");
  };

  return (
    <div id="target-list">
      <Button variant="contained" onClick={handleAdd}>
        +Add List
      </Button>
      <br />
      <br />
      Total: {rows.length}
      <br />
      <br />
      <DataGrid rows={rows} columns={columns} />
    </div>
  );
};

export default TargetList;
