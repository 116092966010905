import { useCallback, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

import { getDatagridContent } from "utils/dataHelper";
import jobs from "data/jobs.json";
import "./lookup.css";

const JobList = () => {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  const fetchData = useCallback(async () => {
    const data = jobs.map((j) => {
      return {
        id: j.key,
        label: j.label,
      };
    });

    const { rows, columns } = getDatagridContent(
      data,
      ["key"],
      null,
      null,
      null
    );

    setRows(rows);
    setColumns(columns);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div id="lookup-list">
      Total: {rows.length}
      <br />
      <br />
      <DataGrid rows={rows} columns={columns} />
    </div>
  );
};

export default JobList;
