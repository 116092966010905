import api from "integration/api";
import { handleError } from "utils/genericHelper";

export const fetchTargetLists = async () => {
  try {
    const response = await api.fetchTargetListsServer();
    if (response.success) {
      return response.data;
    } else {
      handleError(response.error);
    }
  } catch (err) {
    handleError(err);
  }
};

export const fetchTargetListMembers = async (listId) => {
  try {
    const response = await api.fetchTargetListMembersServer(listId);
    if (response.success) {
      return response.data;
    } else {
      handleError(response.error);
    }
  } catch (err) {
    handleError(err);
  }
};

export const createTargetList = async (target) => {
  try {
    const response = await api.createTargetListServer(target);
    if (response.success) {
      return true;
    } else {
      handleError(response.error);
    }
  } catch (err) {
    handleError(err);
  }
};

export const updateTargetList = async (target) => {
  try {
    const response = await api.updateTargetListServer(target);
    if (response.success) {
      return response.data || [];
    } else {
      handleError(response.error);
    }
  } catch (err) {
    handleError(err);
  }
};

export const deleteTargetList = async (id) => {
  try {
    const response = await api.deleteTargetListServer(id);
    if (response.success) {
      return true;
    } else {
      handleError(response.error);
    }
  } catch (err) {
    handleError(err);
  }
};

export const refillTargetList = async (listId) => {
  try {
    const response = await api.refillTargetListServer(listId);
    if (response.success) {
      return true;
    } else {
      handleError(response.error);
    }
  } catch (err) {
    handleError(err);
  }
};

export const emptyTargetList = async (listId) => {
  try {
    const response = await api.emptyTargetListServer(listId);
    if (response.success) {
      return true;
    } else {
      handleError(response.error);
    }
  } catch (err) {
    handleError(err);
  }
};

export const deleteTargetListMember = async (listId, memberId) => {
  try {
    const response = await api.deleteTargetListMemberServer(listId, memberId);
    if (response.success) {
      return true;
    } else {
      handleError(response.error);
    }
  } catch (err) {
    handleError(err);
  }
};

export const addTargetListMembers = async (listId, memberIds) => {
  try {
    const response = await api.addTargetListMembersServer(listId, memberIds);
    if (response.success) {
      return true;
    } else {
      handleError(response.error);
    }
  } catch (err) {
    handleError(err);
  }
};
