import { json, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import * as pollRepo from "repos/poll";
import { fetchTargetLists } from "repos/target";

const PollSave = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [allLists, setAllLists] = useState([]);
  const [lists, setLists] = useState([]);
  const [selectedListId, setSelectedListId] = useState("-1");

  const [form, setForm] = useState({
    title: "",
    summary: "",
    targetLists: "",
    targetUrl: "",
    showcase: false,
    isActive: false,
  });

  useEffect(() => {
    fetchTargetLists().then((data) => {
      setAllLists(data);
      setLists(
        data.filter((el) =>
          (location?.state?.targetLists || "").split(",").includes(el.id)
        )
      );
    });
  }, []);

  useEffect(() => {
    const item = location?.state || {
      id: "",
      title: "",
      summary: "",
      targetLists: "",
      detail: '{"targetUrl":"","showcase":false}',
      isActive: false,
    };

    setForm({
      id: item.id || "",
      title: item.title || "",
      summary: item.summary || "",
      targetLists: item.targetLists || "",
      targetUrl: JSON.parse(item.detail).targetUrl,
      showcase: JSON.parse(item.detail).showcase,
      isActive: item.isActive ?? false,
    });
  }, [location]);

  const handleTextChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSave = async (e) => {
    if (!form.title || !form.summary) {
      alert("Zorunlu alanları doldurunuz");
      return;
    }

    const item = {
      id: form.id,
      title: form.title,
      summary: form.summary,
      targetLists: lists.map((el) => el.id).join(","),
      detail: JSON.stringify({
        targetUrl: form.targetUrl,
        showcase: form.showcase,
      }),
      isActive: form.isActive,
    };

    const saved = form.id
      ? await pollRepo.updatePoll(item)
      : await pollRepo.addPoll(item);
    if (saved) {
      alert("saved");
      navigate("/polls");
    }
  };

  const handleActiveSwitch = (e) => {
    setForm({ ...form, isActive: e.target.checked });
  };

  const handleShowcaseSwitch = (e) => {
    setForm({ ...form, showcase: e.target.checked });
  };

  const handleAddList = (e) => {
    console.log({ lists });
    if (selectedListId !== "-1") {
      if (!lists.find((el) => el.id === selectedListId)) {
        const item = allLists.find((el) => el.id === selectedListId);
        setLists([...lists, item]);
      }
    }
  };

  const handleDeleteList = (e) => {
    const id = e.target.dataset.id;
    setLists(lists.filter((el) => el.id !== id));
  };

  const handleListChange = (e) => {
    setSelectedListId(e.target.value);
  };

  const handleDelete = async (e) => {
    if (confirm("Silmek istediginize emin misiniz?")) {
      await pollRepo.deletePoll(form.id);
      alert("silindi");
      navigate("/polls");
    }
  };

  return (
    <div id="poll-save">
      <legend>
        Title:
        <textarea
          id="title"
          name="title"
          value={form.title}
          maxLength={500}
          onChange={handleTextChange}
        ></textarea>
        <label className="counter">Remaining: {500 - form.title?.length}</label>
      </legend>

      <legend>
        Summary:
        <textarea
          id="summary"
          name="summary"
          value={form.summary}
          maxLength={1000}
          onChange={handleTextChange}
        ></textarea>
        <label className="counter">
          Remaining: {1000 - form.summary?.length}
        </label>
      </legend>

      <legend>
        TARGET LISTS:
        <table border={1}>
          <tbody>
            <tr>
              <td>
                <select
                  style={{ width: "100%" }}
                  value={selectedListId}
                  onChange={handleListChange}
                >
                  <option value={"-1"}>Seçiniz</option>
                  {allLists.map((el) => (
                    <option key={el.id} value={el.id}>
                      {el.title}
                    </option>
                  ))}
                </select>
              </td>
              <td style={{ width: 80 }}>
                <button onClick={handleAddList}>Add List</button>
              </td>
            </tr>

            {lists.map((el) => {
              return (
                <tr key={el.id}>
                  <td>{el.title}</td>
                  <td>
                    <button data-id={el.id} onClick={handleDeleteList}>
                      sil
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </legend>

      <legend>
        Is showcase:{" "}
        <Switch checked={form.showcase} onChange={handleShowcaseSwitch} />
      </legend>

      <legend>
        Target Url:
        <textarea
          id="targetUrl"
          name="targetUrl"
          value={form.targetUrl}
          maxLength={1000}
          onChange={handleTextChange}
        ></textarea>
        <label className="counter">
          Remaining: {1000 - form.targetUrl?.length}
        </label>
      </legend>

      <legend>
        Active: <Switch checked={form.isActive} onChange={handleActiveSwitch} />
      </legend>

      <div>
        <Button variant="contained" onClick={handleSave} className="large">
          SAVE
        </Button>
        &nbsp;&nbsp;&nbsp;
        {form.id && (
          <Button
            variant="contained"
            color="error"
            onClick={handleDelete}
            className="large"
          >
            DELETE
          </Button>
        )}
      </div>
    </div>
  );
};

export default PollSave;
