import { createBrowserRouter } from "react-router-dom";

import Wrapper from "Wrapper";
import Content from "views/content";
import ContentSave from "views/content/ContentSave";
import Group from "views/group";
import GroupSave from "views/group/GroupSave";
import Poll from "views/poll";
import PollSave from "views/poll/PollSave";
import Member from "views/member";
import Whitecode from "views/whitecode";
import WhitecodeDetail from "views/whitecode/WhitecodeDetail";
import MemberSave from "views/member/MemberSave";
import Target from "views/target";
import TargetSave from "views/target/TargetSave";
import TargetMemberList from "views/target/TargetMemberList";
import JobList from "views/lookup/JobList";
import CityList from "views/lookup/CityList";
import CountyList from "views/lookup/CountyList";
import BranchList from "views/lookup/BranchList";
import TitleList from "views/lookup/TitleList";
import Campaign from "views/campaign";
import CampaignSave from "views/campaign/CampaignSave";
import Upload from "views/upload";

import GroupMobile from "views/_mobile/group";
import WhitecodeMobile from "views/_mobile/whitecode";

const routes = createBrowserRouter([
  {
    path: "/mobile",
    element: <Wrapper />,
    children: [
      {
        path: "groups",
        element: <GroupMobile />,
      },
      {
        path: "whitecodes",
        element: <WhitecodeMobile />,
      },
    ],
  },
  {
    path: "/",
    element: <Wrapper />,
    children: [
      {
        path: "contents",
        element: <Content />,
      },
      {
        path: "contents/:contentId",
        element: <ContentSave />,
      },
      {
        path: "groups",
        element: <Group />,
      },
      {
        path: "groups/:groupId",
        element: <GroupSave />,
      },
      {
        path: "polls",
        element: <Poll />,
      },
      {
        path: "polls/:pollId",
        element: <PollSave />,
      },
      {
        path: "members",
        element: <Member />,
      },
      {
        path: "members/:memberId",
        element: <MemberSave />,
      },
      {
        path: "whitecodes",
        element: <Whitecode />,
      },
      {
        path: "whitecodes/:wcId",
        element: <WhitecodeDetail />,
      },
      {
        path: "targets",
        element: <Target />,
      },
      {
        path: "targets/:targetId",
        element: <TargetSave />,
      },
      {
        path: "target-members/:targetId",
        element: <TargetMemberList />,
      },

      {
        path: "campaigns",
        element: <Campaign />,
      },
      {
        path: "campaigns/:campaignId",
        element: <CampaignSave />,
      },

      {
        path: "lookup/jobs",
        element: <JobList />,
      },
      {
        path: "lookup/branches",
        element: <BranchList />,
      },
      {
        path: "lookup/cities",
        element: <CityList />,
      },
      {
        path: "lookup/counties",
        element: <CountyList />,
      },
      {
        path: "lookup/titles",
        element: <TitleList />,
      },
      {
        path: "upload",
        element: <Upload />,
      },
    ],
  },
]);

export default routes;
