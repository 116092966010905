import { useCallback, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

import { getDatagridContent } from "utils/dataHelper";
import cities from "data/cities.json";
import "./lookup.css";

const CountyList = () => {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  const fetchData = useCallback(async () => {
    const data = cities.reduce((acc, cur) => {
      acc.push(
        ...cur.counties.map((j) => ({
          city: cur.label,
          id: j.key,
          label: j.label,
        }))
      );
      return acc;
    }, []);

    const { rows, columns } = getDatagridContent(
      data,
      ["key"],
      null,
      null,
      null
    );

    setRows(rows);
    setColumns(columns);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div id="lookup-list">
      Total: {rows.length}
      <br />
      <br />
      <DataGrid rows={rows} columns={columns} />
    </div>
  );
};

export default CountyList;
