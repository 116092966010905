import lodash from "lodash";
import {
  SET_JWT_TYPE,
  SET_LOADING_TYPE,
  UPDATE_CONFIG_FROM_SERVER_TYPE,
} from "./actionTypes";
import IReduxStore from "types/IReduxStore";

const initialState = {
  ...lodash.cloneDeep(IReduxStore.app),
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING_TYPE:
      return { ...state, loading: action.payload.loading };
    case SET_JWT_TYPE:
      return {
        ...state,
        jwt: action.payload.jwt,
      };
    case UPDATE_CONFIG_FROM_SERVER_TYPE:
      return {
        ...state,
        serverConfig: { ...state.serverConfig, ...action.payload.serverConfig },
      };

    default:
      return state;
  }
};

export default reducer;
