import { Outlet } from "react-router-dom";
import Root from "views/Root";

const Wrapper = () => {
  return (
    <div className="app">
      <Root>
        <Outlet />
      </Root>
    </div>
  );
};

export default Wrapper;
