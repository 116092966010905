import RichTextEditor from "react-simple-wysiwyg";
import {
  BtnBold,
  BtnItalic,
  Editor,
  EditorProvider,
  Toolbar,
} from "react-simple-wysiwyg";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import { CONTENT_TYPE } from "utils/enums";
import { addContent, deleteContent, updateContent } from "repos/content";
import ContentMedia from "./ContentMedia";

const ContentSave = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [form, setForm] = useState({
    title: "",
    category: "",
    origin: "",
    summary: "",
    description: "",
    url: "",
    videoUrl: "",
    images: [],
  });

  useEffect(() => {
    const item = location?.state || {
      id: "",
      title: "",
      category: "",
      post: JSON.stringify({
        origin: "",
        summary: "",
        description: "",
        url: "",
        videoUrl: "",
        images: [],
      }),
    };

    const post = JSON.parse(item.post);

    setForm({
      id: item.id || "",
      title: item.title || "",
      category: item.category || "",
      origin: post.origin || "",
      summary: post.summary || "",
      description: post.description || "",
      url: post.url || "",
      videoUrl: post.videoUrl || "",
      images: post.images || [],
    });
  }, [location]);

  const handleTextChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleDescriptionChange = (e) => {
    setForm({ ...form, description: e.target.value });
  };

  const handleCategoryChange = async (event) => {
    setForm({
      ...form,
      category: event.target.value,
    });
  };

  const handleSave = async (e) => {
    if (
      !form.title ||
      !form.category ||
      !form.origin ||
      !form.summary ||
      !form.description ||
      !form.url
    ) {
      alert("zorunlu alanları doldurunuz");
      return;
    }

    const item = {
      id: form.id,
      title: form.title,
      category: form.category,
      post: JSON.stringify({
        origin: form.origin,
        summary: form.summary,
        description: form.description,
        url: form.url,
        videoUrl: form.videoUrl,
        images: form.images,
      }),
    };

    const saved = form.id ? await updateContent(item) : await addContent(item);
    if (saved) {
      alert("saved");
      navigate("/contents");
    }
  };

  const handleDelete = async (e) => {
    if (window.confirm("Delete the item?")) {
      await deleteContent(form);
      alert("deleted");
      navigate("/contents");
    }
  };

  const handleUpload = (type, data, width, height) => {
    if (type === "video") {
      setForm({
        ...form,
        videoUrl: data,
      });
    } else {
      setForm({
        ...form,
        images: [
          ...form.images,
          {
            uri: data,
            ratio: +(width / height).toFixed(2),
          },
        ],
      });
    }
  };

  const handleDeleteImage = (uri) => {
    setForm({
      ...form,
      images: form.images.filter((el) => el.uri !== uri),
    });
  };

  return (
    <div id="content-save">
      <legend>
        Title:
        <textarea
          id="title"
          name="title"
          value={form.title}
          maxLength={500}
          onChange={handleTextChange}
        ></textarea>
        <label className="counter">Remaining: {500 - form.title?.length}</label>
      </legend>
      <legend>
        Category:
        <Select
          labelId="category"
          id="category"
          value={form.category}
          label="Kategori"
          onChange={handleCategoryChange}
        >
          <MenuItem value={CONTENT_TYPE.News}>News</MenuItem>
          <MenuItem value={CONTENT_TYPE.Announcement}>Announcement</MenuItem>
          <MenuItem value={CONTENT_TYPE.Congress}>Congress</MenuItem>
          <MenuItem value={CONTENT_TYPE.Document}>Document</MenuItem>
          <MenuItem value={CONTENT_TYPE.Education}>Education</MenuItem>
        </Select>
      </legend>
      <legend>
        origin:
        <input
          type="text"
          id="origin"
          name="origin"
          value={form.origin}
          maxLength={500}
          onChange={handleTextChange}
        />
      </legend>
      <legend>
        summary:
        <textarea
          id="summary"
          name="summary"
          value={form.summary}
          maxLength={500}
          onChange={handleTextChange}
        ></textarea>
        <label className="counter">
          Remaining: {500 - form.summary?.length}
        </label>
      </legend>
      <legend>
        description:
        {/* <textarea
          id="description"
          name="description"
          value={form.description}
          maxLength={2500}
          onChange={handleTextChange}
          className="large"
        ></textarea> */}
        <RichTextEditor
          value={form.description}
          onChange={handleDescriptionChange}
        />
        <label className="counter">
          Remaining: {2500 - form.description?.length}
        </label>
      </legend>
      <legend>
        url:
        <textarea
          id="url"
          name="url"
          value={form.url}
          maxLength={500}
          onChange={handleTextChange}
        ></textarea>
      </legend>

      <ContentMedia
        videoUrl={form.videoUrl}
        images={form.images}
        uploadCallback={handleUpload}
        deleteImageCallback={handleDeleteImage}
      />

      <div>
        <Button variant="contained" onClick={handleSave} className="large">
          SAVE
        </Button>{" "}
        &nbsp;
        {form.id && (
          <Button variant="outlined" onClick={handleDelete} className="large">
            DELETE
          </Button>
        )}
      </div>
    </div>
  );
};

export default ContentSave;
