import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import * as groupRepo from "repos/group";

const GroupSave = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [form, setForm] = useState({
    title: "",
    summary: "",
    isActive: false,
  });

  useEffect(() => {
    const item = location?.state || {
      id: "",
      title: "",
      summary: "",
      isActive: false,
    };

    setForm({
      id: item.id || "",
      title: item.title || "",
      summary: item.summary || "",
      isActive: item.isActive ?? false,
    });
  }, [location]);

  const handleTextChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSave = async (e) => {
    if (!form.title || !form.summary) {
      alert("zorunlu alanları doldurunuz");
      return;
    }

    const item = {
      id: form.id,
      title: form.title,
      summary: form.summary,
      isActive: form.isActive,
    };

    const saved = await groupRepo.updateGroup(item);
    if (saved) {
      alert("saved");
      navigate("/groups");
    }
  };

  const handleSwitch = (e) => {
    setForm({ ...form, isActive: e.target.checked });
  };

  return (
    <div id="content-save">
      <legend>
        Title:
        <textarea
          id="title"
          name="title"
          value={form.title}
          maxLength={50}
          onChange={handleTextChange}
        ></textarea>
        <label className="counter">Remaining: {50 - form.title?.length}</label>
      </legend>

      <legend>
        summary:
        <textarea
          id="summary"
          name="summary"
          value={form.summary}
          maxLength={250}
          onChange={handleTextChange}
        ></textarea>
        <label className="counter">
          Remaining: {250 - form.summary?.length}
        </label>
      </legend>
      <legend>
        IsActive: <Switch checked={form.isActive} onChange={handleSwitch} />
      </legend>

      <div>
        <Button variant="contained" onClick={handleSave} className="large">
          SAVE
        </Button>
      </div>
    </div>
  );
};

export default GroupSave;
