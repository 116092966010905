import api from "integration/api";
import { handleError } from "utils/genericHelper";

export const logout = () => {
  //store.dispatch(updateProfileAction(new TProfile()));
  //store.dispatch(setJWTAction(null));
};

export const login = async ({ username, password }) => {
  try {
    const response = await api.loginServer({
      username,
      password,
    });
    if (response.success) {
      return response.data;
    } else {
      handleError(response.error);
    }
  } catch (err) {
    handleError(err);
  }
};

export const upload = async (path, ext, file) => {
  try {
    const response = await api.uploadServer(path, ext, file);
    if (response.success) {
      return response.data;
    } else {
      handleError(response.error);
    }
  } catch (err) {
    handleError(err);
  }
};
