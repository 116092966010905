import { useCallback, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

import { getDatagridContent } from "utils/dataHelper";
import jobs from "data/jobs.json";
import branches from "data/branches.json";
import "./lookup.css";

const BranchList = () => {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  const fetchData = useCallback(async () => {
    const data = [];
    jobs.forEach((j) => {
      const filteredBranches = branches["list" + j.key];

      data.push(
        ...filteredBranches.map((f) => {
          return {
            job: j.label,
            id: j.key + "/" + f.key,
            label: f.label,
          };
        })
      );
    });

    const { rows, columns } = getDatagridContent(
      data,
      ["key"],
      null,
      null,
      null
    );

    setRows(rows);
    setColumns(columns);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div id="lookup-list">
      Total: {rows.length}
      <br />
      <br />
      <DataGrid rows={rows} columns={columns} />
    </div>
  );
};

export default BranchList;
