import { Button, Modal } from "@mui/material";

const WCDetailModal = ({
  item,
  approveCallback,
  deleteCallback,
  closeCallback,
  loading,
}) => {
  const { date, time, place, suspects, story } = JSON.parse(item.post);

  const handleApprove = async () => {
    if (confirm("Onaylamak istediginize emin misiniz?")) {
      await approveCallback(item.id);
    }
  };

  const handleDelete = async () => {
    if (confirm("Silmek istediginize emin misiniz?")) {
      await deleteCallback(item.id);
    }
  };

  return (
    <Modal open hideBackdrop className="custom-modal">
      <div style={{ padding: "1em", textAlign: "center" }}>
        <table style={{ textAlign: "left" }}>
          <tr>
            <td>
              <b>Gönderen: </b>
            </td>
            <td>{item.fullName}</td>
          </tr>
          <tr>
            <td colSpan={2}>
              <hr />
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <b>Tarih / Saat:</b>
            </td>
          </tr>
          <tr>
            <td>{date}</td>
            <td>{time}</td>
          </tr>
          <tr>
            <td colSpan={2}>
              <hr />
            </td>
          </tr>

          <tr>
            <td colSpan={2}>
              <b>Olay yeri:</b>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>{place}</td>
          </tr>
          <tr>
            <td colSpan={2}>
              <hr />
            </td>
          </tr>

          <tr>
            <td colSpan={2}>
              <b>Şüpheliler:</b>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>{suspects}</td>
          </tr>
          <tr>
            <td colSpan={2}>
              <hr />
            </td>
          </tr>

          <tr>
            <td colSpan={2}>
              <b>Olay öyküsü:</b>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>{story}</td>
          </tr>
          <tr>
            <td colSpan={2}>
              <hr />
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <br />
              <Button
                variant="contained"
                tabIndex={0}
                onClick={handleApprove}
                style={{ width: "100%" }}
                disabled={loading}
              >
                APPROVE
              </Button>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <br />
              <Button
                variant="contained"
                color="error"
                tabIndex={0}
                onClick={handleDelete}
                style={{ width: "100%" }}
                disabled={loading}
              >
                DELETE
              </Button>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <br />
              <Button
                variant="outlined"
                tabIndex={0}
                onClick={closeCallback}
                style={{ width: "100%" }}
                disabled={loading}
              >
                CLOSE
              </Button>
            </td>
          </tr>
        </table>
      </div>
    </Modal>
  );
};

export default WCDetailModal;
