import api from "integration/api";
import { handleError } from "utils/genericHelper";

export const fetchPolls = async () => {
  try {
    const response = await api.fetchPollsServer();
    if (response.success) {
      return response.data;
    } else {
      handleError(response.error);
    }
  } catch (err) {
    handleError(err);
  }
};

export const addPoll = async (poll) => {
  try {
    const response = await api.addPollServer(poll);
    if (response.success) {
      return response.data;
    } else {
      handleError(response.error);
    }
  } catch (err) {
    handleError(err);
  }
};

export const updatePoll = async (poll) => {
  try {
    const response = await api.updatePollServer(poll);
    if (response.success) {
      return response.data;
    } else {
      handleError(response.error);
    }
  } catch (err) {
    handleError(err);
  }
};

export const deletePoll = async (id) => {
  try {
    const response = await api.deletePollServer(id);
    if (response.success) {
      return response.data;
    } else {
      handleError(response.error);
    }
  } catch (err) {
    handleError(err);
  }
};
