import { Link } from "react-router-dom";
import { logout } from "utils/appHelper";

const NavBar = () => {
  const href = window.location.href.toLowerCase();

  return (
    <div className="navbar">
      <nav>
        <h4>PAGES</h4>
        <Link
          to={`members`}
          className={href.includes("/members") ? "active" : "pristine"}
        >
          Members
        </Link>
        <Link
          to={`contents`}
          className={href.includes("/contents") ? "active" : "pristine"}
        >
          Contents
        </Link>
        <Link
          to={`groups`}
          className={href.includes("/groups") ? "active" : "pristine"}
        >
          Groups
        </Link>
        <Link
          to={`polls`}
          className={href.includes("/polls") ? "active" : "pristine"}
        >
          Polls
        </Link>
        <Link
          to={`targets`}
          className={href.includes("/targets") ? "active" : "pristine"}
        >
          Target Lists
        </Link>
        <Link
          to={`whitecodes`}
          className={href.includes("/whitecodes") ? "active" : "pristine"}
        >
          Whitecodes
        </Link>
        <Link
          to={`campaigns`}
          className={href.includes("/campaigns") ? "active" : "pristine"}
        >
          Campaigns
        </Link>
        <hr />
        <h4>LOOKUP VALUES</h4>
        <Link
          to={`lookup/jobs`}
          className={href.includes("/jobs") ? "active" : "pristine"}
        >
          Jobs
        </Link>
        <Link
          to={`lookup/branches`}
          className={href.includes("/branches") ? "active" : "pristine"}
        >
          Branches
        </Link>
        <Link
          to={`lookup/cities`}
          className={href.includes("/cities") ? "active" : "pristine"}
        >
          Cities
        </Link>
        <Link
          to={`lookup/counties`}
          className={href.includes("/counties") ? "active" : "pristine"}
        >
          Counties
        </Link>
        <Link
          to={`lookup/titles`}
          className={href.includes("/titles") ? "active" : "pristine"}
        >
          Titles
        </Link>
        <hr />
        <Link
          to={`upload`}
          className={href.includes("/upload") ? "active" : "pristine"}
        >
          Upload
        </Link>
        <hr />
        <Link to={`/`} onClick={logout}>
          <b>Logout</b>
        </Link>
      </nav>
    </div>
  );
};

export default NavBar;
