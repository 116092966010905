import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

const WhitecodeDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [form, setForm] = useState({});

  useEffect(() => {
    const item = location?.state || {};
    setForm(item);
  }, [location]);

  const handleBack = () => {
    history.back();
  };

  return (
    <div id="whitecode-save">
      {[
        "createDate",
        "id",
        "memberId",
        "fullName",
        "isPushed",
        "date",
        "time",
        "place",
        "suspects",
        "story",
      ].map((el, i) => (
        <legend key={i}>
          <b>{el}:</b>{" "}
          {typeof (form[el] !== "string") ? form[el]?.toString() : form[el]}
        </legend>
      ))}

      <div>
        <Button variant="contained" onClick={handleBack} className="large">
          BACK TO LIST
        </Button>
      </div>
    </div>
  );
};

export default WhitecodeDetail;
